<template>
  <div class="register-form">
    <div class="top-section">
      <div class="logo">
        <img src="~@/assets/images/logo/light.png" alt="" />
      </div>
    </div>
    <div class="form-section mt-4 mb-3">
      <b-form @submit.prevent="onRegister">
        <div class="auth-title">
          <h3>Register</h3>
          <p>Please connect your MetaMask wallet or Email to register</p>
        </div>
        <div class="form-group">
          <b-button
            variant="none"
            type="button"
            @click="onRegisterMetamask"
            class="btn-auth d-flex align-items-center justify-content-center"
          >
            Register by MetaMask
          </b-button>
          <b-button
            variant="none"
            type="button"
            @click="registerEmailActive"
            class="btn-auth d-flex align-items-center justify-content-center"
          >
            Register by Email
          </b-button>
        </div>
        <div v-if="registerEmail || register.sponsor" class="mt-4">
          <div class="form-group">
            <label for="email"> Email</label>
            <b-input-group class="">
              <template #prepend>
                <b-input-group-text>
                  <i class="fas fa-envelope"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                type="email"
                required
                id="email"
                v-model.trim="register.email"
                placeholder="Enter Your Email"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="form-group">
            <label for="password"> Password</label>
            <b-input-group class="">
              <template #prepend>
                <b-input-group-text @click="showpass1 = !showpass1" class="cursor-pointer">
                  <i class="fad fa-key"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                :type="!showpass1 ? 'password' : 'text'"
                required
                id="password"
                v-model.trim="register.password"
                placeholder="Enter Your Password"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="form-group">
            <label for="password">Re-Password</label>
            <b-input-group class="">
              <template #prepend>
                <b-input-group-text @click="showpass2 = !showpass2" class="cursor-pointer">
                  <i class="fad fa-key"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                :type="!showpass2 ? 'password' : 'text'"
                required
                id="password"
                v-model.trim="register.password_confirm"
                placeholder="Enter Your Password"
              ></b-form-input>
            </b-input-group>
            <p
              class="text-small text-danger mb-0"
              v-if="register.password_confirm && register.password_confirm !== register.password"
            >
              Re-Password not like password
            </p>
          </div>
          <div class="form-group">
            <label for="sponsor">Sponsor</label>
            <b-input-group class="">
              <template #prepend>
                <b-input-group-text>
                  <i class="fad fa-user"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                type="text"
                id="sponsor"
                v-model.trim="register.sponsor"
                placeholder="Enter Your Sponsor"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="form-group text-center">
            <b-button
              variant="none"
              type="submit"
              class="btn-success min-125 mx-auto d-flex align-items-center justify-content-center"
              :disabled="
                !register.password_confirm || register.password_confirm !== register.password
              "
            >
              Register<i class="fas fa-sign-in-alt ml-1"></i>
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
    <div class="other-section">
      <div class="m-3 text-center redirect-section">
        <h5 class="">Already have an account ?</h5>
        <router-link :to="{ name: 'Login' }">
          Login <i class="fas fa-sign-in-alt ml-1"></i
        ></router-link>
      </div>
    </div>
    <div class="other-section">
      <div class="text-center redirect-section">
        <h5 class="">Resend your email if you don't receive the active email when you sign up.</h5>
        <a @click="showFormResendMail = !showFormResendMail">Resend Mail </a>
      </div>
    </div>
    <b-form v-if="showFormResendMail">
      <div class="form-group text-left">
        <label for="email" class="text-white"> Email</label>
        <b-input-group class="">
          <template #prepend>
            <b-input-group-text>
              <i class="fas fa-envelope"></i>
            </b-input-group-text>
          </template>
          <b-form-input
            type="email"
            required
            id="email"
            v-model.trim="emailResend"
            placeholder="Enter Your Email"
          ></b-form-input>
        </b-input-group>
      </div>
      <div class="form-group">
        <b-button
          variant="none"
          type="button"
          @click="onResendMail"
          class="btn-auth d-flex align-items-center justify-content-center"
        >
          Resend
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Web3 from 'web3';

const axios = require('@/store/config/axios.config').default;

export default {
  data() {
    return {
      registerEmail: false,
      register: {
        email: '',
        password: '',
        sponsor: '',
        token: '',
        password_confirm: '',
      },
      country: '',
      region: '',
      showpass1: false,
      showpass2: false,
      showFormResendMail: false,
      emailResend: '',
    };
  },
  computed: {
    ...mapGetters({
      // typeAccount: 'core/typeAccount',
    }),
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      // eslint-disable-next-line default-case
      switch (mutation.type) {
        case 'user/RESEND_MAIL_SUCCESS':
          this.emailResend = '';
          this.showFormResendMail = false;
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  mounted() {
    if (this.$route.query.email) {
      this.register.email = this.$route.query.email;
    }
    if (this.$route.query.sponsor) {
      this.register.sponsor = this.$route.query.sponsor;
    }
  },
  methods: {
    ...mapMutations(['onLoad', 'outLoad']),
    onResendMail() {
      this.$store.dispatch('user/req_resendMail', { email: this.emailResend });
    },
    async onRegister() {
      const token = await this.genarateCaptChaV3('register');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      this.register.token = token;
      this.$store.dispatch('auth/req_postRegister', this.register);
    },
    registerEmailActive() {
      if (this.registerEmail === false) {
        this.registerEmail = true;
      } else {
        this.registerEmail = false;
      }
    },
    async onRegisterMetamask() {
      try {
        const checkWallet = await this.checkWallet();
        if (!checkWallet) {
          return;
        }
        // let sponsor = 456319;
        let sponsor = '';
        if (this.$route.query.sponsor) {
          sponsor = this.$route.query.sponsor;
        }
        const address = Web3.utils.toChecksumAddress(await this.$store.state.wallet.addr);
        const data = await axios.post('api/v1/address/register', {
          address,
          sponsor,
        });
        if (!data) {
          this.$store.commit('auth/REGISTER_SUCCESS');
          return;
        }
        if (data && data.message) {
          const signature = data.message;
          const txData = await window.ethereum.request({
            method: 'personal_sign',
            params: [address, signature],
          });
          // eslint-disable-next-line no-shadow
          const data2 = await axios.post('api/v1/address/register', {
            address,
            signature: txData,
            message: data.message,
            sponsor,
          });
          if (data2 && data2.id) {
            this.$store.commit('auth/REGISTER_SUCCESS');
          }
        } else {
          this.$toastr.e('Data is null', 'Oops!');
        }
      } catch (e) {
        this.$toastr.e(e.message, 'Oops!');
        this.outLoad();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.min-125 {
  min-width: 125px;
}
.auth-title {
  text-align: center;
  margin-bottom: 25px;
  h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
  }
  p {
    color: #fff;
  }
}
.register-form {
  min-width: 274px;
  max-width: calc(100% - 50px);
  span {
    &.effect {
      background: #009750;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
    }
    &.eggsbook {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
      color: #5d5d5d;
    }
  }
  .top-section {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 35px auto 20px;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      flex: 0 0 100px;
      min-width: 100px;
      img {
        height: auto;
        width: 250px;
        margin-bottom: 5px;
      }
    }
    .hint-section {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      color: #212529;
      .hint-main {
        font-weight: 900;
        font-size: clamp(14px, 1.5vw, 18px);
        line-height: 120%;
        margin-bottom: 5px;
      }
      .hint-sub {
        font-size: clamp(14px, 1vw, 16px);
        color: #98a6ad;
        line-height: 115%;
        font-weight: 600;
      }
      span {
        &.effect {
          background: #fff;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: clamp(14px, 1.5vw, 18px);
          text-transform: uppercase;
          font-weight: 900;
          line-height: 120%;
        }
        &.eggsbook {
          font-size: clamp(14px, 1.5vw, 18px);
          text-transform: uppercase;
          font-weight: 900;
          color: #5d5d5d;
          line-height: 120%;
        }
      }
    }
  }
  .form-section {
    max-width: 100%;
    margin: 0 auto;
    label {
      font-weight: 400;
      color: #fff;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .input-group-text {
      background: transparent;
      padding: 0;
      border: 1px solid #fff;
      width: 37px;
      height: 37px;
      display: flex;
      color: #fff;
      justify-content: center;
      align-items: center;
    }
    select,
    input {
      border: 1px solid #e9ecef;
      height: 37px;
      font-size: clamp(15px, 1vw, 16.2px);
      font-weight: 600;
      padding: 0.375rem 0.75rem;
      border-radius: 5px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      max-width: calc(100% - 45px);
      &:focus-visible,
      &:focus {
        box-shadow: 0 0 0 1px rgba(56, 169, 73, 45%);
        outline: none;
      }
      &::placeholder {
        font-weight: 500;
        font-size: clamp(13px, 1vw, 15px);
      }
    }
    .form-group {
      .forgot {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        i,
        svg {
          margin-right: 5px;
          color: #98a6ad;
          font-size: clamp(14px, 1.5vw, 16px);
        }
        span a {
          color: #98a6ad;
          font-weight: 600;
          font-size: clamp(14px, 1.5vw, 16px);
        }
      }
    }
  }
  button {
    margin-top: 20px;
    &.btn-auth {
      border: 1px solid #008cff;
      box-shadow: 0 2px 6px 0 #008cff;
      letter-spacing: 0.05rem;
      position: relative;
      background: #008cff;
      color: #fff !important;
      min-width: 130px;
      width: 100%;
      height: 40px;
      overflow: hidden;
      transition: 0.3s ease-in-out;
      border-radius: 0.3rem;
      z-index: 1;
      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .other-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h6 {
      color: #5d5b6f;
      font-size: clamp(15px, 1vw, 16px);
      font-weight: 600;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        display: inline-block;
        margin: 0 14px 4px 0;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
      &:after {
        display: inline-block;
        margin: 0 0 4px 14px;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
    }
    button {
      margin-top: 20px;
      border-radius: 50%;
      border: 0;
      background: linear-gradient(45deg, #7ac14e73, #0f9c4f70);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      color: #fff;
      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
        background: #009750;
      }
    }
    .redirect-section {
      font-weight: 600;
      width: 100%;
      h5 {
        font-size: clamp(14px, 1.5vw, 16px);
        font-weight: 600;
        color: #fff;
      }
      a {
        margin: 20px auto;
        box-shadow: 0 2px 6px 0 #008cff;
        border-radius: 5px;
        border: 0;
        background: #008cff;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 40px;
        text-decoration: none;
        color: #fff;
        &:hover,
        &:focus,
        &:focus-visible {
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>
